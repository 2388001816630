import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequireAuthenticatedGuard } from './guards/require-authenticated.guard';
import { RequireGuestGuard } from './guards/require-guest.guard';
import { RequireOneOfActionGroupsGuard } from './guards/require-one-of-action-groups.guard';

const routes: Routes = [
  {
    path: 'schedule',
    loadChildren: () => import('./pages/schedule/schedule.module').then((m) => m.ScheduleModule),
  },
  {
    path: 'locations',
    loadChildren: () => import('./pages/locations/locations.module').then((m) => m.LocationsModule),
  },
  {
    path: 'store',
    loadChildren: () => import('./pages/store/store.module').then((m) => m.StoreModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutModule),
    canActivate: [RequireAuthenticatedGuard],
  },
  {
    path: 'settings',
    pathMatch: 'full',
    redirectTo: 'admin/settings',
  },
  { path: 'settings/reports', redirectTo: 'admin/reports' },
  { path: 'settings/users', pathMatch: 'full', redirectTo: 'admin/users' },
  { path: 'settings/users/:id', redirectTo: 'admin/users/:id' },
  {
    path: 'admin',
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'admin/settings' },
      {
        path: 'settings',
        loadChildren: () => import('./pages/admin/settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [RequireOneOfActionGroupsGuard],
        data: {
          groups: ['app_settings', 'admin_settings', 'stripe_settings'],
        },
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/admin/users/users.module').then((m) => m.UsersModule),
        canActivate: [RequireOneOfActionGroupsGuard],
        data: {
          groups: ['users', 'event_credits', 'invoices', 'linked_accounts'],
        },
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/admin/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [RequireOneOfActionGroupsGuard],
        data: {
          groups: ['statistics'],
        },
      },
      {
        path: 'user-roles',
        loadChildren: () => import('./pages/admin/user-roles/user-roles.module').then((m) => m.UserRolesModule),
        canActivate: [RequireOneOfActionGroupsGuard],
        data: {
          groups: ['roles'],
        },
      },
    ],
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [RequireAuthenticatedGuard],
  },
  {
    path: 'emails',
    children: [
      {
        path: 'confirm',
        loadChildren: () =>
          import('./pages/email-actions/confirm-email/confirm-email.module').then((m) => m.ConfirmEmailModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('./pages/email-actions/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'schedule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [RequireOneOfActionGroupsGuard, RequireGuestGuard],
})
export class AppRoutingModule {}
