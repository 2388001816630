<form nz-form [formGroup]="formGroup" (ngSubmit)="submit()">
  <nz-form-item>
    <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired nzFor="first_name">First Name</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="First name is required">
      <input nz-input formControlName="first_name" id="first_name" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired nzFor="preferred_name">Preferred Name</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <input nz-input formControlName="preferred_name" id="preferred_name" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired nzFor="last_name">Last Name</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Last name is required">
      <input nz-input formControlName="last_name" id="last_name" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="7" [nzXs]="24" [nzRequired]="requiredBaseFields.includes('birthday')" nzFor="birthday">
      Birthday
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <nz-date-picker nzAllowClear formControlName="birthday" id="birthday"></nz-date-picker>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="7" [nzXs]="24" [nzRequired]="requiredBaseFields.includes('phone_number')" nzFor="phone">
      Phone
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="phoneErrorTip">
      <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate" formGroupName="phone">
        <ng-template #addOnBeforeTemplate>
          <nz-select formControlName="country" (ngModelChange)="countryCodeUpdated()">
            <nz-option *ngFor="let code of callingCodes" [nzLabel]="code.label" [nzValue]="code.country"></nz-option>
          </nz-select>
        </ng-template>
        <input formControlName="number" id="'number'" nz-input (blur)="formatPhoneNumber()" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="7" [nzXs]="24" [nzRequired]="requiredBaseFields.includes('gender')" nzFor="gender">
      Gender
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <nz-select nzAllowClear formControlName="gender" id="gender">
        <nz-option nzValue="male" nzLabel="Male"></nz-option>
        <nz-option nzValue="female" nzLabel="Female"></nz-option>
        <nz-option nzValue="nonbinary" nzLabel="Non Binary"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="7" [nzXs]="24" [nzRequired]="requiredBaseFields.includes('pronouns')" nzFor="pronouns">
      Pronouns
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <nz-select nzMode="multiple" nzAllowClear formControlName="pronouns" id="pronouns">
        <nz-option nzValue="he" nzLabel="he/him"></nz-option>
        <nz-option nzValue="she" nzLabel="she/her"></nz-option>
        <nz-option nzValue="they" nzLabel="they/them"></nz-option>
        <nz-option nzValue="ze" nzLabel="ze/zir"></nz-option>
        <nz-option nzValue="xe" nzLabel="xe/xim"></nz-option>
        <nz-option nzValue="co" nzLabel="co/co"></nz-option>
        <nz-option nzValue="ey" nzLabel="ey/em"></nz-option>
        <nz-option nzValue="ve" nzLabel="ve/ver"></nz-option>
        <nz-option nzValue="per" nzLabel="per/per"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <ng-container formGroupName="additional_fields">
    <nz-form-item *ngFor="let field of fields">
      <nz-form-label
        [nzRequired]="requiredAdditionalFieldIds.includes(field.id)"
        [nzSm]="7"
        [nzXs]="24"
        [nzFor]="field.id"
      >
        {{ field.name }}
      </nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Input is invalid">
        <nz-select *ngIf="field.type === 'enum'" nzAllowClear [formControlName]="field.id" [id]="field.id">
          <nz-option *ngFor="let value of field.allowed_values" [nzValue]="value" [nzLabel]="value"></nz-option>
        </nz-select>
        <input nz-input *ngIf="field.type === 'string'" [formControlName]="field.id" [id]="field.id" />
        <nz-date-picker
          *ngIf="field.type === 'date'"
          [formControlName]="field.id"
          [id]="field.id"
          nzAllowClear
        ></nz-date-picker>
        <input
          *ngIf="field.type === 'phone-number'"
          [formControlName]="field.id"
          [id]="field.id"
          nz-input
          (blur)="formatCustomFieldPhone(field)"
        />
        <label *ngIf="field.type === 'boolean'" [formControlName]="field.id" [id]="field.id" nz-checkbox></label>
      </nz-form-control>
    </nz-form-item>
  </ng-container>

  <nz-form-item nz-row class="form-buttons">
    <nz-form-control [nzSm]="{ span: 14, offset: 7 }">
      <button type="button" nz-button nzType="default" (click)="cancel()">Cancel</button>
      <button nz-button [nzLoading]="isLoading" nzType="primary">Submit</button>
    </nz-form-control>
  </nz-form-item>
</form>

<ng-template #emailErrorTip let-control>
  <div class="ant-form-explain" *ngIf="control.hasError('required')">A valid email is required</div>
  <div class="ant-form-explain" *ngIf="control.hasError('email')">Please enter a valid email address</div>
  <div class="ant-form-explain" *ngIf="control.hasError('notUnique')">This email has already been taken</div>
</ng-template>

<ng-template #phoneErrorTip let-control>
  <div class="ant-form-explain" *ngIf="control.hasError('invalid')">Please enter a valid phone number</div>
</ng-template>
