import { Waiver } from '../application-settings/liability-waiver.service';
import { UserProfile } from 'src/app/models/auth/user-profile';
import { AdditionalUserField } from 'src/app/models/application-settings/app-settings';

export interface OtherViolatedRestriction {
  name: string;
  message: string;
  [key: string]: any;
}

export interface LiabilityWaiverRequiredRestriction {
  name: 'liability-waiver-required';
  message: string;
  waiver: Waiver;
  user?: Pick<UserProfile, 'id' | 'display_name'>;
  user_signed_previous_version: boolean;
}

export interface MissingRequiredFieldsRestriction {
  name: 'missing-required-fields';
  message: string;
  missing_base_fields: string[];
  missing_additional_fields: AdditionalUserField[];
}
export interface BirthdayRequiredRestriction {
  name: 'birthday-required';
  message: string;
}

export interface GenderRequiredRestriction {
  name: 'gender-required';
  message: string;
}

export interface EmergencyContactRequiredRestriction {
  name: 'emergency-contact-required';
  message: string;
}

export type ViolatedRestriction =
  | LiabilityWaiverRequiredRestriction
  | BirthdayRequiredRestriction
  | GenderRequiredRestriction
  | EmergencyContactRequiredRestriction
  | MissingRequiredFieldsRestriction
  | OtherViolatedRestriction;

export function isLiabilityWaiverRequired(
  restriction: ViolatedRestriction
): restriction is LiabilityWaiverRequiredRestriction {
  return restriction.name === 'liability-waiver-required';
}

export function isMissingRequiredFields(
  restriction: ViolatedRestriction
): restriction is MissingRequiredFieldsRestriction {
  return restriction.name === 'missing-required-fields';
}

export function isBirthdayRequired(restriction: ViolatedRestriction): restriction is BirthdayRequiredRestriction {
  return restriction.name === 'birthday-required';
}

export function isGenderRequired(restriction: ViolatedRestriction): restriction is GenderRequiredRestriction {
  return restriction.name === 'gender-required';
}

export function isEmergencyContactRequired(
  restriction: ViolatedRestriction
): restriction is EmergencyContactRequiredRestriction {
  return restriction.name === 'emergency-contact-required';
}

export class ViolatedRestrictionsError extends Error {
  constructor(message: string, public violations: ViolatedRestriction[]) {
    super(message);
  }
}
