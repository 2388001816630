import { ErrorHandler, Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SentryService } from './sentry/sentry.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private sentry: SentryService, private notificationService: NzNotificationService) {}

  handleError(error: any) {
    const original = error.originalError || error.rejection || error;

    this.sentry.captureException(original);

    console.error(error);

    const title = original.title || 'Something went wrong';

    this.error(title, original.message || original);
  }

  private error(title: string, details: string) {
    if (this.notificationService) {
      this.notificationService.error(title, details);
    }
  }
}
