<form nz-form [formGroup]="formGroup" (ngSubmit)="submit()">
  <nz-form-item>
    <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired for="birthday">
      Birtday
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="birthdayErrorTip">
      <nz-date-picker formControlName="birthday" id="birthday"></nz-date-picker>
    </nz-form-control>
  </nz-form-item>
</form>

<div *nzModalFooter>
  <button type="button" nz-button nzType="primary" nzGhost (click)="cancel()">Cancel</button>
  <button nz-button nzType="primary" [nzLoading]="isSubmitting" (click)="submit()">
    Update {{ whoAmI.id === user.id ? 'your' : user.display_name + "'s'" }} birthday
  </button>
</div>
