import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, filter } from 'rxjs/operators';
import { UserInfo, UserProfile } from '../models/auth/user-profile';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RequireOneOfActionGroupsGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const actionGroups = (route.data.groups as Array<string>) || [];

    return this.authService.user$.pipe(
      filter(user => !user.isLoading),
      take(1),
      switchMap(user => (user.isAuthenticated ? of(user) : this.promptForLogin())),
      map(user => {
        if (user.isAuthenticated && this.userHasAtLeastOneActionGroup(user.user, actionGroups)) {
          return true;
        } else {
          return this.router.createUrlTree(['schedule']);
        }
      })
    );
  }

  private promptForLogin(): Observable<UserInfo> {
    return this.authService.promptForLogin().pipe(
      switchMap(() => this.authService.user$),
      filter(user => !user.isLoading),
      take(1)
    );
  }

  private userHasAtLeastOneActionGroup(user: UserProfile, groups: string[]): boolean {
    return (user.actions || []).some(action => action.group === '*' || groups.includes(action.group));
  }
}
