// @ts-ignore
import meta from 'libphonenumber-js/metadata.min.json';
import {
  CountryCode,
  MetadataJson,
  parsePhoneNumber as _parsePhoneNumber,
  parsePhoneNumberFromString as _parsePhoneNumberFromString,
  PhoneNumber,
} from 'libphonenumber-js/core';

export { CountryCode, MetadataJson };

export const metadata = meta as MetadataJson;

export function parsePhoneNumber(text: string, defaultCountry?: CountryCode): PhoneNumber {
  return _parsePhoneNumber(text, defaultCountry, metadata);
}

export function parsePhoneNumberFromString(text: string, defaultCountry?: CountryCode): PhoneNumber | undefined {
  return _parsePhoneNumberFromString(text, defaultCountry, metadata);
}

export const callingCodeOptions = Object.entries(metadata.country_calling_codes).flatMap(([prefix, countries]) =>
  countries.map((country) => {
    return {
      code: `+${prefix}`,
      country,
      label: country,
    };
  })
);
