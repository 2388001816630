import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { filter, flatMap, map, take } from 'rxjs/operators';
import { RequestBirthdayDialogComponent } from 'src/app/core/dialogs/request-birthday-dialog/request-birthday-dialog.component';
import { RequestGenderDialogComponent } from 'src/app/core/dialogs/request-gender-dialog/request-gender-dialog.component';
import { SignLiabilityWaiverDialogComponent } from 'src/app/core/dialogs/sign-liability-waiver-dialog/sign-liability-waiver-dialog.component';
import { UpdateEmergencyContactDialogComponent } from 'src/app/core/dialogs/update-emergency-contact-dialog/update-emergency-contact-dialog.component';
import { UpdateProfileDialogComponent } from 'src/app/core/dialogs/update-profile-dialog/update-profile-dialog.component';
import { UserProfile } from 'src/app/models/auth/user-profile';
import { LinkedAccountInfo } from 'src/app/models/user/linked-account-response';
import { AppSettingsService } from '../application-settings/app-settings.service';
import { AuthService } from '../auth.service';
import {
  BirthdayRequiredRestriction,
  EmergencyContactRequiredRestriction,
  GenderRequiredRestriction,
  isBirthdayRequired,
  isEmergencyContactRequired,
  isGenderRequired,
  isLiabilityWaiverRequired,
  isMissingRequiredFields,
  LiabilityWaiverRequiredRestriction,
  MissingRequiredFieldsRestriction,
  ViolatedRestriction,
} from '../calendar/restrictions-violated.error';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class ViolatedRestrictionResolverService {
  private whoAmI$ = this.authService.user$;

  constructor(
    private userService: UsersService,
    private appSettings: AppSettingsService,
    private authService: AuthService,
    private dialogService: NzModalService
  ) {}

  public async resolveRetriction(
    user: UserProfile | LinkedAccountInfo,
    restriction: ViolatedRestriction
  ): Promise<boolean> {
    if (isLiabilityWaiverRequired(restriction)) {
      return this.handleLiabilityWaiverRestriction(restriction, user);
    } else if (isBirthdayRequired(restriction)) {
      return this.handleBirthdayRequiredRestriction(restriction, user);
    } else if (isGenderRequired(restriction)) {
      return this.handleGenderRequiredRestriction(restriction, user);
    } else if (isEmergencyContactRequired(restriction)) {
      return this.handleEmergencyContactRequiredRestriction(restriction, user);
    } else if (isMissingRequiredFields(restriction)) {
      return this.handleMissingRequiredFields(restriction, user);
    }
    return false;
  }

  public isResolvable(restriction: ViolatedRestriction) {
    return (
      isLiabilityWaiverRequired(restriction) ||
      isBirthdayRequired(restriction) ||
      isGenderRequired(restriction) ||
      isEmergencyContactRequired(restriction) ||
      isMissingRequiredFields(restriction)
    );
  }

  public allAreResolvable(restrictions: ViolatedRestriction[]) {
    return restrictions.every((r) => this.isResolvable(r));
  }

  private async handleLiabilityWaiverRestriction(
    restriction: LiabilityWaiverRequiredRestriction,
    user: UserProfile | LinkedAccountInfo
  ) {
    const whoAmI = await this.whoAmI$
      .pipe(
        filter((me) => !me.isLoading),
        take(1)
      )
      .toPromise();

    if (!whoAmI.isAuthenticated) {
      return false;
    }

    const title = restriction.user_signed_previous_version
      ? `Please review and accept the updated ${restriction.waiver ? restriction.waiver.name : ''} liability waver`
      : `Pleases review & accept the ${restriction.waiver ? restriction.waiver.name : ''} liability waiver`;

    return this.dialogService
      .create<SignLiabilityWaiverDialogComponent>({
        nzTitle: title,
        nzFooter: null,
        nzContent: SignLiabilityWaiverDialogComponent,
        nzComponentParams: {
          user: restriction.user ? restriction.user : user,
          whoAmI: whoAmI.user,
          waiver: restriction.waiver,
        },
        nzWidth: '900px',
        nzStyle: {
          'max-width': '100%',
        },
      })
      .afterClose.pipe(
        take(1),
        map((result) => !!result)
      )
      .toPromise();
  }

  private async handleMissingRequiredFields(
    restriction: MissingRequiredFieldsRestriction,
    user: UserProfile | LinkedAccountInfo
  ) {
    const whoAmI = await this.whoAmI$
      .pipe(
        filter((me) => !me.isLoading),
        take(1)
      )
      .toPromise();

    if (!whoAmI.isAuthenticated) {
      return false;
    }

    const userInfo = await this.userService.getUser(user.id).toPromise();
    const settings = await this.appSettings.settings$.pipe(take(1)).toPromise();

    return this.dialogService
      .create<UpdateProfileDialogComponent>({
        nzTitle: `Please provide values for all of the required fields for ${user.display_name}`,
        nzFooter: null,
        nzContent: UpdateProfileDialogComponent,
        nzComponentParams: {
          user: userInfo,
          settings,
          requiredBaseFields: restriction.missing_base_fields,
          requiredAdditionalFieldIds: restriction.missing_additional_fields.map((field) => field.id),
        },
      })
      .afterClose.pipe(
        take(1),
        map((result) => !!result)
      )
      .toPromise();
  }

  private async handleBirthdayRequiredRestriction(
    restriction: BirthdayRequiredRestriction,
    user: UserProfile | LinkedAccountInfo
  ) {
    const whoAmI = await this.whoAmI$
      .pipe(
        filter((me) => !me.isLoading),
        take(1)
      )
      .toPromise();

    if (!whoAmI.isAuthenticated) {
      return false;
    }

    return this.dialogService
      .create<RequestBirthdayDialogComponent>({
        nzTitle: 'Birthday required for age restricted event',
        nzFooter: null,
        nzContent: RequestBirthdayDialogComponent,
        nzComponentParams: {
          user,
          whoAmI: whoAmI.user,
        },
      })
      .afterClose.pipe(
        take(1),
        map((result) => !!result)
      )
      .toPromise();
  }

  private async handleGenderRequiredRestriction(
    restriction: GenderRequiredRestriction,
    user: UserProfile | LinkedAccountInfo
  ) {
    const whoAmI = await this.whoAmI$
      .pipe(
        filter((me) => !me.isLoading),
        take(1)
      )
      .toPromise();

    if (!whoAmI.isAuthenticated) {
      return false;
    }

    return this.dialogService
      .create<RequestGenderDialogComponent>({
        nzTitle: 'Gender required for gender restricted event',
        nzFooter: null,
        nzContent: RequestGenderDialogComponent,
        nzComponentParams: {
          user,
          whoAmI: whoAmI.user,
        },
      })
      .afterClose.pipe(
        take(1),
        map((result) => !!result)
      )
      .toPromise();
  }

  private async handleEmergencyContactRequiredRestriction(
    restriction: EmergencyContactRequiredRestriction,
    user: UserProfile | LinkedAccountInfo
  ) {
    const whoAmI = await this.whoAmI$
      .pipe(
        filter((me) => !me.isLoading),
        take(1)
      )
      .toPromise();

    if (!whoAmI.isAuthenticated) {
      return false;
    }

    return this.userService
      .getUser(user.id)
      .pipe(
        flatMap(
          (u) =>
            this.dialogService.create<UpdateEmergencyContactDialogComponent>({
              nzTitle: 'Please setup an emergency contact',
              nzFooter: null,
              nzContent: UpdateEmergencyContactDialogComponent,
              nzComponentParams: {
                user: u,
              },
              nzWidth: '600px',
              nzStyle: {
                'max-width': '100%',
              },
            }).afterClose
        ),
        take(1),
        map((result) => !!result)
      )
      .toPromise();
  }
}
