import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AdditionalUserField, AppSettings } from 'src/app/models/application-settings/app-settings';
import { UserProfile } from 'src/app/models/auth/user-profile';
import { UsersService } from 'src/app/services/user/users.service';
import { validateForm } from 'src/app/utils/form-group';
import { callingCodeOptions, parsePhoneNumber, parsePhoneNumberFromString } from 'src/app/utils/phone-number';

@Component({
  selector: 'app-update-profile-dialog',
  templateUrl: './update-profile-dialog.component.html',
  styleUrls: ['./update-profile-dialog.component.less'],
})
export class UpdateProfileDialogComponent implements OnInit {
  isLoading = false;
  user: UserProfile;
  settings: AppSettings;

  requiredBaseFields: string[] = [];
  requiredAdditionalFieldIds: number[] = [];

  callingCodes = callingCodeOptions;

  formGroup: UntypedFormGroup;
  additionalFieldsGroup: UntypedFormGroup;
  fields: AdditionalUserField[];

  constructor(
    private userManagementService: UsersService,
    private notificationService: NzNotificationService,
    private ref: NzModalRef
  ) {}

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      first_name: new FormControl<string>('', Validators.required),
      last_name: new FormControl<string>('', Validators.required),
      preferred_name: new FormControl<string>(''),
      pronouns: new FormControl<string[]>([]),
      birthday: new UntypedFormControl(undefined, this.requiredBaseFields.includes('birthday') ? Validators.required : []),
      phone: new UntypedFormGroup({
        country: new FormControl<string>('US'),
        number: new FormControl<string>(undefined, (control) => {
          if (!this.formGroup || !this.formGroup.get('phone').value.country || !control.value) {
            if (this.requiredBaseFields.includes('phone_number')) {
              return Validators.required(control);
            }
            return null;
          }

          const phoneNumber = parsePhoneNumberFromString(control.value, this.formGroup.get('phone').value.country);
          if (!phoneNumber || !phoneNumber.isValid) {
            return { invalid: true };
          }
        }),
      }),
      gender: new FormControl<string>(undefined, this.requiredBaseFields.includes('gender') ? Validators.required : []),
    });

    this.fields = this.settings.additional_user_fields.filter((field) => !field.hidden);
    this.additionalFieldsGroup = new UntypedFormGroup(
      this.fields.reduce((acc, field) => {
        return { ...acc, [field.id]: new UntypedFormControl('', this.createValidatorForField(field)) };
      }, {})
    );

    this.formGroup.addControl('additional_fields', this.additionalFieldsGroup);

    const birthday = this.user.birthday ? DateTime.fromISO(this.user.birthday).toJSDate() : null;
    const value = {
      ...this.user,
      birthday,
      phone: this.user.phone || {},
    };

    this.formGroup.patchValue(value);
    this.formatPhoneNumber();
  }

  createValidatorForField(field: AdditionalUserField): ValidatorFn | null {
    switch (field.type) {
      case 'phone-number':
        return (control) => {
          if (!control.value) {
            return this.requiredAdditionalFieldIds.includes(field.id) ? Validators.required(control) : null;
          }
          // TODO: support non US phone numbers?
          const phoneNumber = parsePhoneNumberFromString(control.value, 'US');
          console.log(phoneNumber);
          if (!phoneNumber || !phoneNumber.isValid) {
            return { invalid: true };
          }
        };
      case 'enum':
      case 'date':
      case 'string':
      case 'boolean':
      default:
        return this.requiredAdditionalFieldIds.includes(field.id) ? Validators.required : null;
    }
  }

  formatCustomFieldPhone(field: AdditionalUserField) {
    const control = this.additionalFieldsGroup.get(field.id.toString());

    if (!control || !control.value) {
      return;
    }

    if (control.value && control.valid) {
      const formatted = parsePhoneNumber(control.value, 'US').formatNational();
      control.patchValue(formatted, { emitEvent: false });
    }
  }

  formatPhoneNumber() {
    const control = this.formGroup.get('phone');

    if (!control || !control.value) {
      return;
    }

    if (control.value.number && control.value.country) {
      if (control.valid) {
        const formatted = parsePhoneNumber(control.value.number, control.value.country).formatNational();
        control.patchValue({ number: formatted }, { emitEvent: false });
      }
    }
  }

  countryCodeUpdated() {
    (this.formGroup.get('phone') as UntypedFormGroup).get('number').updateValueAndValidity();
  }

  cancel() {
    this.ref.close();
  }

  async submit() {
    validateForm(this.formGroup);

    if (!this.formGroup.valid) {
      return;
    }

    const values = this.formGroup.value;
    const birthday = values.birthday && DateTime.fromJSDate(values.birthday).toISODate();

    let phone;
    if (values.phone.number && values.phone.country) {
      const formatted = parsePhoneNumber(values.phone.number, values.phone.country);
      phone = {
        number: formatted.format('E.164'),
        country: values.phone.country,
      };
    }

    this.isLoading = true;
    await this.userManagementService
      .updateUser(this.user.id, {
        ...values,
        birthday,
        phone,
      })
      .then((result) => this.ref.close(result))
      .catch((err) => {
        console.log(err);
        this.notificationService.error('Something went wrong', 'We were unable to update your profile');
      })
      .finally(() => (this.isLoading = false));
  }
}
