import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private tokenEndpointBlacklist = [
    '/api/sessions',
    '/api/password_resets',
    '/api/password_resets/update',
    '/api/confirm',
  ];
  private retryBlacklist = ['/api/current-user'];

  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.tokenEndpointBlacklist.includes(request.url)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.shouldRetryRequest(error, request)) {
          this.auth.user$.pipe(
            flatMap((user) => {
              if (user.isAuthenticated) {
                return this.auth
                  .promptForLogin()
                  .pipe(
                    flatMap(() => throwError({ title: 'You must log back in', message: 'Redirecting to sign-in page' }))
                  );
              } else {
                return throwError({
                  title: 'You are now logged out',
                  message: `Some requests were in flight before you logged out. This shouldn't cause any issues.`,
                });
              }
            })
          );
          // TODO: redirect to /sign-in
          return this.auth
            .promptForLogin()
            .pipe(flatMap(() => throwError({ title: 'You must log back in', message: 'Redirecting to sign-in page' })));
        }

        return throwError(error);
      })
    );
  }

  private shouldRetryRequest(error: HttpErrorResponse, request: HttpRequest<any>): boolean {
    return error.status === 401 && !this.retryBlacklist.includes(request.url);
  }
}
