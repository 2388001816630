import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { UserRole } from 'src/app/models/admin/users/user-role';
import { ClientProfile, CreateUserRequest, UserProfile } from 'src/app/models/auth/user-profile';
import { PagedResponse } from 'src/app/models/base/paged-response';
import { ApiSuccess } from 'src/app/models/base/responses';
import { LinkedAccountInfo } from 'src/app/models/user/linked-account-response';
import { Waiver } from '../application-settings/liability-waiver.service';
import { ViolatedRestriction } from '../calendar/restrictions-violated.error';
import { UserEmailService } from './user-email.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private httpClient: HttpClient, private emailService: UserEmailService) {}

  public roles$ = this.httpClient.get<ApiSuccess<UserRole[]>>('/api/user-roles').pipe(
    map((body) => body.data),
    shareReplay(1)
  );

  public getAccountRestrictions(user: Pick<UserProfile | LinkedAccountInfo, 'id'>): Promise<ViolatedRestriction[]> {
    return this.httpClient
      .get<ApiSuccess<ViolatedRestriction[]>>(`/api/users/${user.id}/restrictions`)
      .pipe(
        map((body) => body.data),
        take(1)
      )
      .toPromise();
  }

  public updateUserRoles(user: UserProfile, roles: string[]): Promise<UserProfile> {
    return this.httpClient
      .put<ApiSuccess<UserProfile>>(`/api/users/${user.id}/roles`, { roles })
      .pipe(
        map((body) => body.data),
        take(1)
      )
      .toPromise();
  }

  public createUser(user: CreateUserRequest): Promise<UserProfile> {
    return this.httpClient
      .post<ApiSuccess<UserProfile>>('/api/users', { user })
      .pipe(
        map((body) => body.data),
        take(1)
      )
      .toPromise();
  }

  public updateSignedWaiver(user: UserProfile | LinkedAccountInfo, waiver: Waiver): Promise<UserProfile> {
    return this.httpClient
      .put<ApiSuccess<UserProfile>>(`/api/users/${user.id}`, { user: { liability_waiver_id: waiver.waiver.id } })
      .pipe(
        map((body) => body.data),
        take(1)
      )
      .toPromise();
  }

  public updateUser(userId: string, user: Partial<UserProfile>): Promise<UserProfile> {
    return this.httpClient
      .put<ApiSuccess<UserProfile>>(`/api/users/${userId}`, { user })
      .pipe(
        map((body) => body.data),
        take(1)
      )
      .toPromise();
  }

  public deleteUser(user: { id: string }): Promise<any> {
    return this.httpClient.delete(`/api/users/${user.id}`).pipe(take(1)).toPromise();
  }

  public listClients(sortField: string = 'name', sortOrder: string = 'ascend'): Promise<ClientProfile[]> {
    let params = new HttpParams();
    if (sortField && sortOrder) {
      params = params.append('sort.field', sortField).append('sort.order', sortOrder);
    }
    return this.httpClient
      .get<ApiSuccess<ClientProfile[]>>('/api/clients', {
        params,
      })
      .pipe(
        map((body) => body.data),
        take(1)
      )
      .toPromise();
  }

  public getUser(id: string): Observable<UserProfile> {
    return this.httpClient.get<ApiSuccess<UserProfile>>(`/api/users/${id}`).pipe(map((body) => body.data));
  }

  public async getAllUsers(sortField: string = 'name', sortOrder: string = 'ascend'): Promise<UserProfile[]> {
    return this.getUsersHelper(1, 100, sortField, sortOrder, []);
  }

  private async getUsersHelper(
    page: number,
    size: number,
    sortField: string,
    sortOrder: string,
    userAccumulator: UserProfile[]
  ): Promise<UserProfile[]> {
    return this.getUsers(page, size, sortField, sortOrder, []).then((result: PagedResponse<UserProfile[]>) => {
      const users = [...userAccumulator, ...result.data];

      if (users.length < result.total) {
        return this.getUsersHelper(page + 1, size, sortField, sortOrder, users);
      } else {
        return users;
      }
    });
  }

  // TODO: make this observable
  public getUsers(
    pageIndex: number = 1,
    pageSize: number = 10,
    sortField: string | null,
    sortOrder: string | null,
    roles: string[],
    name: string | null = null
  ): Promise<PagedResponse<UserProfile[]>> {
    let params = new HttpParams().append('start', `${(pageIndex - 1) * pageSize}`).append('count', `${pageSize}`);

    if (sortField && sortOrder) {
      params = params.append('sort.field', sortField).append('sort.order', sortOrder);
    }

    if (name) {
      params = params.append('name', name);
    }

    roles.forEach((role) => (params = params.append('role[]', role)));

    return this.httpClient.get<PagedResponse<UserProfile[]>>('/api/users', { params }).pipe(take(1)).toPromise();
  }
}
