import { Component } from '@angular/core';
import { LinkedAccountInfo } from 'src/app/models/user/linked-account-response';
import { UserProfile } from 'src/app/models/auth/user-profile';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UsersService } from 'src/app/services/user/users.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { validateForm } from 'src/app/utils/form-group';

@Component({
  selector: 'app-request-gender-dialog',
  templateUrl: './request-gender-dialog.component.html',
  styleUrls: ['./request-gender-dialog.component.less'],
})
export class RequestGenderDialogComponent {
  user: UserProfile | LinkedAccountInfo;

  whoAmI: UserProfile;

  formGroup = new UntypedFormGroup({
    gender: new UntypedFormControl(null, Validators.required),
  });

  isSubmitting = false;

  constructor(private userService: UsersService, private ref: NzModalRef) {}

  get genderErrorTip() {
    return (
      'We need ' +
      (this.user.id === this.whoAmI.id ? 'your' : this.user.display_name + `'s`) +
      ' gender due to the gender restriction on this event'
    );
  }

  cancel() {
    this.ref.close();
  }

  async submit() {
    validateForm(this.formGroup);

    if (!this.formGroup.valid) {
      return;
    }

    this.isSubmitting = true;
    await this.userService
      .updateUser(this.user.id, { gender: this.formGroup.value.gender })
      .then(() => this.ref.close(true))
      .finally(() => (this.isSubmitting = false));
  }
}
