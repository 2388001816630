import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UserProfile } from 'src/app/models/auth/user-profile';
import { validateForm } from 'src/app/utils/form-group';
import { callingCodeOptions, parsePhoneNumber, parsePhoneNumberFromString } from 'src/app/utils/phone-number';
import { UsersService } from 'src/app/services/user/users.service';

@Component({
  selector: 'app-update-emergency-contact-dialog',
  templateUrl: './update-emergency-contact-dialog.component.html',
  styleUrls: ['./update-emergency-contact-dialog.component.less'],
})
export class UpdateEmergencyContactDialogComponent implements OnInit {
  user: UserProfile;
  isLoading = false;

  callingCodes = callingCodeOptions;

  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    phone: new UntypedFormGroup({
      country: new UntypedFormControl('US', Validators.required),
      number: new UntypedFormControl(undefined, [
        Validators.required,
        (control) => {
          if (!this.formGroup || !this.formGroup.get('phone').value.country || !control.value) {
            return null;
          }

          const phoneNumber = parsePhoneNumberFromString(control.value, this.formGroup.get('phone').value.country);
          if (!phoneNumber || !phoneNumber.isValid) {
            return { invalid: true };
          }
        },
      ]),
    }),
  });

  constructor(
    private ref: NzModalRef,
    private userService: UsersService,
    private notificationService: NzNotificationService
  ) {}

  ngOnInit() {
    const emergencyContact = this.user.emergency_contact;

    if (emergencyContact) {
      this.formGroup.patchValue({ ...emergencyContact, phone: emergencyContact.phone || {} });
    }
  }

  formatPhoneNumber() {
    const control = this.formGroup.get('phone');

    if (!control || !control.value) {
      return;
    }

    if (control.value.number && control.value.country) {
      if (control.valid) {
        const formatted = parsePhoneNumber(control.value.number, control.value.country).formatNational();
        control.patchValue({ number: formatted }, { emitEvent: false });
      }
    }
  }

  countryCodeUpdated() {
    (this.formGroup.get('phone') as UntypedFormGroup).get('number').updateValueAndValidity();
  }

  cancel() {
    this.ref.close();
  }

  async submit() {
    validateForm(this.formGroup);

    if (!this.formGroup.valid) {
      return;
    }

    const values = this.formGroup.value;

    this.isLoading = true;
    await this.userService
      .updateUser(this.user.id, { emergency_contact: values })
      .then((result) => this.ref.close(result))
      .catch((err) => {
        console.log(err);
        this.notificationService.error(
          'Something went wrong',
          'We were unable to update your emergency contact information'
        );
      })
      .finally(() => (this.isLoading = false));
  }
}
