import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzNotificationModule, NzNotificationService } from 'ng-zorro-antd/notification';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './app.error-handler';
import { CoreModule } from './core/core.module';
import { AuthFormSeparatorComponent } from './dialogs/auth/log-in-dialog/auth-form-separator/auth-form-separator.component';
import { ForgotPasswordFormComponent } from './dialogs/auth/log-in-dialog/forgot-password-form/forgot-password-form.component';
import { LogInDialogComponent } from './dialogs/auth/log-in-dialog/log-in-dialog.component';
import { LogInFormComponent } from './dialogs/auth/log-in-dialog/log-in-form/log-in-form.component';
import { RegisterFormComponent } from './dialogs/auth/log-in-dialog/register-form/register-form.component';
import { SignInTabComponent } from './dialogs/auth/log-in-dialog/sign-in-tab/sign-in-tab.component';
import { SocialLoginButtonComponent } from './dialogs/auth/log-in-dialog/social-login-button/social-login-button.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SentryModule } from './sentry/sentry.module';
import { SentryService } from './sentry/sentry.service';
import { ConfirmDialogComponent } from './services/confirmation/confirm-dialog/confirm-dialog.component';
import { LoggerInterceptor } from './interceptors/logger.interceptor';
import { NzConfig } from 'ng-zorro-antd/core/config';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    LogInDialogComponent,
    LogInFormComponent,
    RegisterFormComponent,
    ForgotPasswordFormComponent,
    ConfirmDialogComponent,
    SignInTabComponent,
    SocialLoginButtonComponent,
    AuthFormSeparatorComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'x-csrf-token',
      headerName: 'x-csrf-token',
    }),
    NzBreadCrumbModule,
    NzGridModule,
    NzLayoutModule,
    NzMenuModule,
    NzNotificationModule,
    NzResultModule,
    NzTabsModule,
    SentryModule,
    NgxStripeModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    Title,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
      deps: [SentryService, NzNotificationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggerInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
