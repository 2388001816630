import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SentryConfig, SENTRY_CONFIG } from './sentry.config';
import { SentryService } from './sentry.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class SentryModule {}
