<div class="page-wrapper">
  <div nz-row class="navbar" tabindex="-1" (blur)="mobileMenuVisible = false">
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="5" [nzLg]="5" [nzXXl]="4">
      <ng-container *ngIf="brandName$ | async as name; else loadingBrandName">
        <a [href]="brandUrl$ | async" target="_blank" class="logo">
          <h1>{{ name }}</h1>
        </a>
      </ng-container>
      <ng-template #loadingBrandName>
        <h1 class="loading-name-box"><span></span></h1>
      </ng-template>
    </div>
    <div nz-col [nzXs]="0" [nzSm]="0" [nzMd]="19" [nzLg]="19" [nzXXl]="20" data-cy="navbar">
      <ng-container *ngTemplateOutlet="menuTemplate; context: { mode: 'horizontal' }"></ng-container>
    </div>
    <div class="menu-button" *ngIf="showMobileMenu$ | async">
      <button nz-button nzType="link" nzGhost (click)="mobileMenuVisible = !mobileMenuVisible">
        <i nz-icon nzType="menu"></i>
      </button>
    </div>

    <div
      data-cy="mobile-menu"
      class="mobile-menu"
      [@collapseMotion]="(showMobileMenu$ | async) ? (mobileMenuVisible ? 'expanded' : 'collapsed') : 'hidden'"
    >
      <ng-container *ngTemplateOutlet="menuTemplate; context: { mode: 'inline' }"></ng-container>
    </div>
  </div>
  <div nz-row class="page-content-wrapper">
    <ng-container *ngIf="sideBarMenus$ | async as menuItems">
      <div
        data-cy="sidebar"
        nz-col
        [nzXs]="0"
        [nzSm]="0"
        [nzMd]="menuItems.length > 0 ? 5 : 0"
        [nzLg]="menuItems.length > 0 ? 5 : 0"
        [nzXXl]="menuItems.length > 0 ? 4 : 0"
        class="sidebar-menu"
      >
        <ul nz-menu nzMode="inline" class="sider-menu">
          <ng-container *ngFor="let item of menuItems">
            <li
              nz-menu-item
              [ngClass]="{ 'ant-menu-item-selected': isCurrentRoute(item) | async }"
              [routerLink]="item.path"
              *ngIf="item.path; else sideBarButton"
            >
              <span>{{ item.name }}</span>
            </li>
            <ng-template #sideBarButton>
              <li nz-menu-item (click)="item.click()">
                <span>{{ item.name }}</span>
              </li>
            </ng-template>
          </ng-container>
        </ul>
      </div>
      <div
        nz-col
        [nzXs]="24"
        [nzSm]="24"
        [nzMd]="menuItems.length > 0 ? 19 : 24"
        [nzLg]="menuItems.length > 0 ? 19 : 24"
        [nzXXl]="menuItems.length > 0 ? 20 : 24"
        class="content-wrapper"
      >
        <div class="breadcrumbs" *ngIf="showMobileMenu$ | async">
          <nz-breadcrumb>
            <nz-breadcrumb-item *ngFor="let crumb of breadcrumbs$ | async">{{ crumb.name }}</nz-breadcrumb-item>
          </nz-breadcrumb>
        </div>
        <div class="content">
          <router-outlet></router-outlet>
        </div>
        <div class="footer">
          <div class="main">Schedule Guru ©{{ date | date: 'yyyy' }} Ascent Software Design, LLC</div>
          <div class="versions">{{ versions.version }}-{{ versions.revision }}@{{ versions.branch }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<nz-spin nzSize="large" class="page-loading-spinner" [nzSpinning]="true" *ngIf="loading$ | async"></nz-spin>

<ng-template #menuTemplate let-mode="mode">
  <ul nz-menu nzTheme="dark" [nzMode]="mode" class="header-menu">
    <ng-container *ngFor="let route of (navBarEntries$ | async) || []; trackBy: navBarRouteTrackFn">
      <li
        nz-menu-item
        [ngClass]="{ selected: currentEntryName === route.name }"
        *ngIf="route.sub_routes.length === 0; else submenu"
      >
        <a [routerLink]="route.path" *ngIf="route.path; else menuClick">{{ route.name }}</a>
        <ng-template #menuClick>
          <a (click)="route.click()">{{ route.name }}</a>
        </ng-template>
      </li>
      <ng-template #submenu>
        <li nz-submenu [nzTitle]="route.name" [ngClass]="{ selected: currentEntryName === route.name }">
          <ul>
            <li nz-menu-item *ngFor="let sub of route.sub_routes">
              <a [routerLink]="sub.path" *ngIf="sub.path; else subMenuClick">{{ sub.name }}</a>
              <ng-template #subMenuClick>
                <a (click)="sub.click()">{{ sub.name }}</a>
              </ng-template>
            </li>
          </ul>
        </li>
      </ng-template>
    </ng-container>
  </ul>
</ng-template>
