import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

export function validateForm(formGroup: UntypedFormGroup | UntypedFormArray) {
  // tslint:disable-next-line: forin
  for (const i in formGroup.controls) {
    formGroup.controls[i].markAsDirty();
    formGroup.controls[i].updateValueAndValidity();

    if (formGroup.controls[i] instanceof UntypedFormGroup || formGroup.controls[i] instanceof UntypedFormArray) {
      validateForm(formGroup.controls[i]);
    }
  }
}
