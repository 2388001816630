import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class LoggerInterceptor implements HttpInterceptor {
  private static requestLoggerParamName = 'request_logger';

  constructor(private route: ActivatedRoute) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.route.snapshot.queryParamMap.has(LoggerInterceptor.requestLoggerParamName)) {
      return next.handle(
        request.clone({
          setParams: {
            [LoggerInterceptor.requestLoggerParamName]: this.route.snapshot.queryParamMap.get(
              LoggerInterceptor.requestLoggerParamName
            ),
          },
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
