<form nz-form [formGroup]="formGroup" (ngSubmit)="submit()">
  <nz-form-item>
    <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired nzFor="name">Name</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Contact name is required">
      <input nz-input formControlName="name" id="name" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired [nzSm]="7" [nzXs]="24" nzFor="phone">Phone</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="phoneErrorTip">
      <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate" formGroupName="phone">
        <ng-template #addOnBeforeTemplate>
          <nz-select formControlName="country" (ngModelChange)="countryCodeUpdated()">
            <nz-option *ngFor="let code of callingCodes" [nzLabel]="code.label" [nzValue]="code.country"></nz-option>
          </nz-select>
        </ng-template>
        <input formControlName="number" id="'number'" nz-input (blur)="formatPhoneNumber()" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
</form>

<ng-container *nzModalFooter>
  <button type="button" nz-button nzType="default" (click)="cancel()">Cancel</button>
  <button nz-button [nzLoading]="isLoading" nzType="primary" (click)="submit()">Submit</button>
</ng-container>

<ng-template #phoneErrorTip let-control>
  <div class="ant-form-explain" *ngIf="control.hasError('invalid')">Please enter a valid phone number</div>
  <div class="ant-form-explain" *ngIf="control.hasError('required')">A phone number is required</div>
</ng-template>
