import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BaseService } from '../base.service';
import { LinkedAccountInfo } from 'src/app/models/user/linked-account-response';
import { UserProfile } from 'src/app/models/auth/user-profile';

export interface WaiverVersion {
  id: string;
  contents: string;
  version: number;
  last_updated: string;
}

export interface Waiver {
  id: string;
  name: string;
  waiver: WaiverVersion;
}

export interface SignatureRequest {
  id: string;
  waiver: Waiver;
  for_user: Pick<UserProfile | LinkedAccountInfo, 'id' | 'display_name'>;
}

export interface SignedWaiver {
  date: string;
  id: string;
  waiver: Waiver;
}

@Injectable({
  providedIn: 'root',
})
export class LiabilityWaiverService extends BaseService {
  constructor(httpClient: HttpClient, private notificationService: NzNotificationService) {
    super(httpClient);
  }

  public getWaivers() {
    return this.list<Waiver>('/api/settings/liability-waivers');
  }

  public getWaiver(id: string) {
    return this.get<Waiver>(`/api/settings/liability-waivers/${id}`);
  }

  public updateWaiver(id: string, updates: Partial<Waiver>) {
    return this.update<Waiver>(`/api/settings/liability-waivers/${id}`, { waiver: updates });
  }

  public createWaiver(params: Partial<Waiver>) {
    return this.create<Waiver>(`/api/settings/liability-waivers`, { waiver: params });
  }

  public deleteWaiver(id: string) {
    return this.delete(`/api/settings/liability-waivers/${id}`);
  }

  public acknowledgeWaiver(userId: string, waiverId: string) {
    return this.create<any>(`/api/users/${userId}/acknowledged-waivers`, { waiver: { id: waiverId } });
  }

  public getSignedWaivers(userId: string) {
    return this.list<SignedWaiver>(`/api/users/${userId}/acknowledged-waivers`);
  }

  public getPendingWaivers(userId: string) {
    return this.list<SignatureRequest>(`/api/users/${userId}/signature-requests`);
  }

  public createSignatureRequest(userId: string, waiver: Pick<Waiver, 'id'>) {
    return this.create<SignatureRequest>(`/api/users/${userId}/signature-requests`, { waiver });
  }

  public deleteSignatureRequest(userId: string, request: SignatureRequest) {
    return this.delete(`/api/users/${userId}/signature-requests/${request.id}`);
  }
}
