import { Component } from '@angular/core';
import { UsersService } from 'src/app/services/user/users.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserProfile } from 'src/app/models/auth/user-profile';
import { LinkedAccountInfo } from 'src/app/models/user/linked-account-response';
import { DateTime } from 'luxon';
import { validateForm } from 'src/app/utils/form-group';

@Component({
  selector: 'app-request-birthday-dialog',
  templateUrl: './request-birthday-dialog.component.html',
  styleUrls: ['./request-birthday-dialog.component.less'],
})
export class RequestBirthdayDialogComponent {
  user: UserProfile | LinkedAccountInfo;

  whoAmI: UserProfile;

  formGroup = new UntypedFormGroup({
    birthday: new UntypedFormControl(null, Validators.required),
  });

  isSubmitting = false;

  constructor(private userService: UsersService, private ref: NzModalRef) {}

  get birthdayErrorTip() {
    return (
      'We need ' +
      (this.user.id === this.whoAmI.id ? 'your' : this.user.display_name + `'s`) +
      ' birthday due to the age restriction on this event'
    );
  }

  cancel() {
    this.ref.close();
  }

  async submit() {
    validateForm(this.formGroup);

    if (!this.formGroup.valid) {
      return;
    }

    const date = DateTime.fromJSDate(this.formGroup.value.birthday).toISODate();

    this.isSubmitting = true;
    await this.userService
      .updateUser(this.user.id, { birthday: date })
      .then(() => this.ref.close(true))
      .finally(() => (this.isSubmitting = false));
  }
}
