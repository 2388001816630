import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { RequestBirthdayDialogComponent } from './dialogs/request-birthday-dialog/request-birthday-dialog.component';
import { RequestGenderDialogComponent } from './dialogs/request-gender-dialog/request-gender-dialog.component';
import { SignLiabilityWaiverDialogComponent } from './dialogs/sign-liability-waiver-dialog/sign-liability-waiver-dialog.component';
import { CoreIconsProviderModule } from './icons-provider.module';
import { ActionCheckPipe } from './pipes/action-check.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { ObsWithStatusPipe } from './pipes/obs-with-status.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { PrimaryEmailValuePipe } from './pipes/primary-email-value.pipe';
import { RoleCheckPipe } from './pipes/role-check.pipe';
import { TextColorPipe } from './pipes/text-color.pipe';
import { UtcDatetimeDisplayPipe } from './pipes/utc-datetime-display.pipe';
import { EventDetailsViewComponent } from './views/event-details-view/event-details-view.component';
import { SessionClassDetailsViewComponent } from './views/session-class-details-view/session-class-details-view.component';
import { UpdateEmergencyContactDialogComponent } from './dialogs/update-emergency-contact-dialog/update-emergency-contact-dialog.component';
import { UpdateProfileDialogComponent } from './dialogs/update-profile-dialog/update-profile-dialog.component';
import { ProfilePaymentMethodsViewComponent } from './views/profile-payment-methods-view/profile-payment-methods-view.component';
import { AddPaymentMethodDialogComponent } from './dialogs/add-payment-method-dialog/add-payment-method-dialog.component';
import { NgxStripeModule } from 'ngx-stripe';
import { CommunicationPreferencesTabComponent } from './views/communication-preferences-tab/communication-preferences-tab.component';
import { UpdateCommunicationPreferencesComponent } from './dialogs/update-communication-preferences/update-communication-preferences.component';
import { AddEmailDialogComponent } from './dialogs/add-email-dialog/add-email-dialog.component';
import { CreditUsageViewComponent } from './views/credit-usage-view/credit-usage-view.component';
import { HasActionPipe } from './pipes/has-action.pipe';

@NgModule({
  declarations: [
    AddEmailDialogComponent,
    PhonePipe,
    ObsWithStatusPipe,
    EventDetailsViewComponent,
    SessionClassDetailsViewComponent,
    RoleCheckPipe,
    UtcDatetimeDisplayPipe,
    SignLiabilityWaiverDialogComponent,
    RequestBirthdayDialogComponent,
    RequestGenderDialogComponent,
    PrimaryEmailValuePipe,
    ActionCheckPipe,
    HasActionPipe,
    TextColorPipe,
    CurrencyFormatPipe,
    UpdateEmergencyContactDialogComponent,
    UpdateProfileDialogComponent,
    ProfilePaymentMethodsViewComponent,
    AddPaymentMethodDialogComponent,
    CommunicationPreferencesTabComponent,
    UpdateCommunicationPreferencesComponent,
    CreditUsageViewComponent,
  ],
  imports: [
    CommonModule,
    CoreIconsProviderModule,
    FormsModule,
    NgxStripeModule.forChild(),
    NzButtonModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzInputNumberModule,
    NzListModule,
    NzModalModule,
    NzSelectModule,
    NzSpinModule,
    NzToolTipModule,
    NzTypographyModule,
    ReactiveFormsModule,
  ],
  exports: [
    ActionCheckPipe,
    HasActionPipe,
    AddEmailDialogComponent,
    CoreIconsProviderModule,
    CommunicationPreferencesTabComponent,
    UpdateCommunicationPreferencesComponent,
    EventDetailsViewComponent,
    FormsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzInputNumberModule,
    NzModalModule,
    NzListModule,
    NzSelectModule,
    NzSpinModule,
    NzToolTipModule,
    NzTypographyModule,
    ObsWithStatusPipe,
    PhonePipe,
    PrimaryEmailValuePipe,
    ReactiveFormsModule,
    RoleCheckPipe,
    SessionClassDetailsViewComponent,
    TextColorPipe,
    UtcDatetimeDisplayPipe,
    CurrencyFormatPipe,
    UpdateEmergencyContactDialogComponent,
    UpdateProfileDialogComponent,
    ProfilePaymentMethodsViewComponent,
    CreditUsageViewComponent,
  ],
})
export class CoreModule {}
