import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserEmail } from 'src/app/models/auth/user-profile';
import { ApiSuccess } from 'src/app/models/base/responses';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserEmailService {
  constructor(private httpClient: HttpClient) {}

  public addEmail(userId: string, email: Pick<UserEmail, 'value'>): Promise<UserEmail> {
    return this.httpClient
      .post<ApiSuccess<UserEmail>>(`/api/users/${userId}/emails`, { email })
      .pipe(
        map(body => body.data),
        take(1)
      )
      .toPromise();
  }

  public removeEmail(userId: string, emailId: string): Promise<any> {
    return this.httpClient
      .delete(`/api/users/${userId}/emails/${emailId}`)
      .pipe(take(1))
      .toPromise();
  }

  public getEmailsForUser(userId: string): Observable<UserEmail[]> {
    return this.httpClient.get<ApiSuccess<UserEmail[]>>(`/api/users/${userId}/emails`).pipe(
      map(body => body.data),
      take(1)
    );
  }

  public makePrimary(userId: string, email: UserEmail): Promise<any> {
    return this.httpClient
      .put(`/api/users/${userId}/primary-email`, { email: { id: email.id } })
      .pipe(take(1))
      .toPromise();
  }

  public resendConfirmation(userId: string, email: UserEmail): Promise<any> {
    return this.httpClient
      .post(`/api/users/${userId}/resend-confirmation`, { email: { id: email.id } })
      .pipe(take(1))
      .toPromise();
  }
}
