import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { StripeFactoryService, StripeInstance } from 'ngx-stripe';
import { merge, Observable, of, Subject } from 'rxjs';
import { defaultIfEmpty, filter, map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { AdditionalUserField, AppSettings } from 'src/app/models/application-settings/app-settings';
import { CompanyInfo } from 'src/app/models/application-settings/company-info';
import { StripeSettings } from 'src/app/models/application-settings/stripe-settings';
import { ApiSuccess } from 'src/app/models/base/responses';

const LOCAL_STORE_APP_SETTINGS_KEY = 'schedule-guru.app-settings';

function cacheToLocalStorage(settings: AppSettings): void {
  localStorage.setItem(LOCAL_STORE_APP_SETTINGS_KEY, JSON.stringify(settings));
}

function getCachedSettings(): AppSettings | null {
  const cached = localStorage.getItem(LOCAL_STORE_APP_SETTINGS_KEY);

  if (cached) {
    const obj = JSON.parse(cached);
    if (obj) {
      return obj;
    }
  }

  return null;
}

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  private settingsUpdate = new Subject<AppSettings>();
  private stripeSettingsUpdate = new Subject<StripeSettings>();
  private companyInfoUpdate = new Subject<CompanyInfo>();

  constructor(private httpClient: HttpClient, private stripeFactory: StripeFactoryService) {
    const cached = getCachedSettings();

    if (cached) {
      this.settingsUpdate.next(cached);
    }
  }

  public settings$: Observable<AppSettings> = merge(this.settingsUpdate, this.fetchSettings()).pipe(
    map((settings) => {
      return { ...settings, default_color: settings.default_color || '#00803e' };
    }),
    shareReplay(1)
  );

  public subscription$ = this.value((settings) => settings.subscription);

  public stripeSettings$ = this.settings$.pipe(
    map((settings) => settings.stripe),
    mergeMap((stripe) => {
      if(stripe.connected) {
        return of(stripe);
      }
      else {
        return this.fetchStripeSettings();
      }
    })
  );
  public stripeService$: Observable<StripeInstance | null> = this.settings$.pipe(
    filter((settings) => settings.stripe.connected && !!settings.stripe.stripe_user_id),
    map((settings) =>
      this.stripeFactory.create(settings.sg_stripe_pk, {
        stripeAccount: settings.stripe.stripe_user_id,
      })
    ),
    defaultIfEmpty(null),
    shareReplay(1)
  );
  public sgStripe$: Observable<StripeInstance | null> = this.settings$.pipe(
    filter((settings) => !!settings.sg_stripe_pk),
    map((settings) => this.stripeFactory.create(settings.sg_stripe_pk)),
    defaultIfEmpty(null),
    shareReplay(1)
  );

  public companyInfo$ = merge(this.companyInfoUpdate, this.fetchCompanyInfo()).pipe(shareReplay(1));

  public applicationName$ = this.value((settings) => settings.brand_name);
  public applicationBrandUrl$ = this.value((settings) => settings.brand_url);
  public applicationTimezone$ = this.value((settings) => settings.default_timezone);
  public applicationScheduleFirstDayOfWeek$ = this.value((settings) => settings.schedule_first_day_of_week);
  public applicationPlannerFirstDayOfWeek$ = this.value((settings) => settings.planner_first_day_of_week);
  public eventColorSource$ = this.value((settings) => settings.event_color_source);
  public defaultColor$ = this.value((settings) => settings.default_color);
  public enabledSocialLogins$ = this.value((settings) => settings.social_login_providers || []);

  public updateAdditionalUserField(field: AdditionalUserField): Promise<any> {
    return this.httpClient
      .post<ApiSuccess<AppSettings>>('/api/settings/user-fields', { field })
      .pipe(
        map((body) => body.data),
        tap((updated) => this.settingsUpdate.next(updated)),
        tap((updated) => cacheToLocalStorage(updated)),
        take(1)
      )
      .toPromise();
  }

  public updateSettings(settings: Partial<AppSettings>): Promise<any> {
    return this.httpClient
      .put<ApiSuccess<AppSettings>>('/api/settings', { settings })
      .pipe(
        map((body) => body.data),
        tap((updated) => this.settingsUpdate.next(updated)),
        tap((updated) => cacheToLocalStorage(updated)),
        take(1)
      )
      .toPromise();
  }

  public updateCompanyInfo(settings: Partial<CompanyInfo>): Promise<any> {
    return this.httpClient
      .put<ApiSuccess<CompanyInfo>>('/api/settings/admin', { settings })
      .pipe(
        map((body) => body.data),
        tap((updated) => this.companyInfoUpdate.next(updated)),
        take(1)
      )
      .toPromise();
  }

  public disconnectStripe(): Promise<any> {
    return this.httpClient
      .get<ApiSuccess<StripeSettings>>('/api/stripe/disconnect')
      .pipe(
        map((body) => body.data),
        tap((updated) => {
          this.stripeSettingsUpdate.next(updated);
          this.fetchSettings()
            .pipe(take(1))
            .subscribe((settings) => this.settingsUpdate.next(settings));
        }),
        take(1)
      )
      .toPromise();
  }

  public refetchSettings() {
    this.fetchSettings()
      .pipe(take(1))
      .subscribe((settings) => this.settingsUpdate.next(settings));
  }

  private value<T>(fn: (settings: AppSettings) => T): Observable<T> {
    return this.settings$.pipe(map(fn));
  }

  private fetchSettings(): Observable<AppSettings> {
    return this.httpClient.get<ApiSuccess<AppSettings>>('/api/settings').pipe(
      map((body) => body.data),
      tap((settings) => cacheToLocalStorage(settings))
    );
  }

  private fetchCompanyInfo(): Observable<CompanyInfo> {
    return this.httpClient.get<ApiSuccess<CompanyInfo>>('/api/settings/admin').pipe(map((body) => body.data));
  }

  private fetchStripeSettings(): Observable<StripeSettings> {
    return this.httpClient.get<ApiSuccess<StripeSettings>>('/api/stripe/settings').pipe(map((body) => body.data));
  }
}
