import { Action, actionMatches, UserRole } from '../admin/users/user-role';
import { PhoneNumber } from '../phone-number';

export interface UserEmail {
  value: string;
  confirmed: boolean;
  id: string;
}

export interface UserProfile {
  id: string;
  display_name: string;
  last_name: string;
  first_name: string;
  preferred_name?: string;
  pronouns?: string[];
  display_pronouns?: string;
  primary_email?: UserEmail;
  emails: UserEmail[];
  last_login_timestamp: string,
  roles: string[];
  actions: Action[];
  birthday?: string;
  gender?: string;
  phone?: PhoneNumber;
  owner?: UserProfile;
  account_balance: number;

  emergency_contact?: {
    name: string;
    phone: PhoneNumber;
  };

  additional_fields: object;
}

export type ClientProfile = Pick<UserProfile, 'id' | 'first_name' | 'last_name' | 'display_name' | 'display_pronouns'>;

export interface UserInfo {
  user?: UserProfile;
  isAuthenticated: boolean;
  isLoading: boolean;
}

export interface CreateUserRequest {
  last_name: string;
  first_name: string;
  primary_email: Pick<UserEmail, 'value'>;
  roles: UserRole[];
  is_subcontractor?: boolean;
  company_name?: string;
}

export function userHasAction(user: UserProfile, group: string, name?: string): boolean {
  return (user.actions || []).some((action) => actionMatches(action, group, name));
}

export function getPrimaryEmail(user: UserProfile): UserEmail {
  if (user.primary_email) {
    return user.primary_email;
  } else {
    return null;
  }
}

export function getPrimaryEmailValue(user: UserProfile): string {
  const email = getPrimaryEmail(user);

  if (email) {
    return email.value;
  } else {
    return null;
  }
}
