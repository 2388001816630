<form nz-form [formGroup]="formGroup" (ngSubmit)="submit()">
  <nz-form-item>
    <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired for="gender">
      Gender
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="genderErrorTip">
      <nz-select nzAllowClear formControlName="gender" id="gender">
        <nz-option nzValue="male" nzLabel="Male"></nz-option>
        <nz-option nzValue="female" nzLabel="Female"></nz-option>
        <nz-option nzValue="nonbinary" nzLabel="Non Binary"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>

<div *nzModalFooter>
  <button type="button" nz-button nzType="primary" nzGhost (click)="cancel()">Cancel</button>
  <button nz-button nzType="primary" [nzLoading]="isSubmitting" (click)="submit()">
    Update {{ whoAmI.id === user.id ? 'your' : user.display_name + "'s'" }} gender
  </button>
</div>
