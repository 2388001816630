import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { UserProfile } from 'src/app/models/auth/user-profile';
import { LinkedAccountInfo } from 'src/app/models/user/linked-account-response';
import { LiabilityWaiverService, Waiver } from 'src/app/services/application-settings/liability-waiver.service';
import { validateForm } from 'src/app/utils/form-group';

@Component({
  selector: 'app-sign-liability-waiver-dialog',
  templateUrl: './sign-liability-waiver-dialog.component.html',
  styleUrls: ['./sign-liability-waiver-dialog.component.less'],
})
export class SignLiabilityWaiverDialogComponent implements OnInit {
  user: Pick<UserProfile | LinkedAccountInfo, 'id' | 'display_name'>;

  whoAmI: UserProfile;

  formGroup = new UntypedFormGroup({
    acknowledge: new UntypedFormControl(false, Validators.requiredTrue),
    canAcknowledgeForUser: new UntypedFormControl(true, Validators.requiredTrue),
  });

  isSubmitting = false;
  isLoading = false;
  waiver: Waiver = null;

  constructor(private waiverService: LiabilityWaiverService, private ref: NzModalRef) {}

  ngOnInit() {
    if (!this.whoAmI || this.whoAmI.id !== this.user.id) {
      this.formGroup.get('canAcknowledgeForUser').setValue(false);
    }
  }

  cancel() {
    this.ref.close();
  }

  async submit() {
    validateForm(this.formGroup);

    if (!this.formGroup.valid) {
      return;
    }

    this.isSubmitting = true;
    await this.waiverService
      .acknowledgeWaiver(this.user.id, this.waiver.id)
      .toPromise()
      .then(() => this.ref.close(true))
      .finally(() => (this.isSubmitting = false));
  }
}
