import { HttpClient } from '@angular/common/http';
import { ApiSuccess } from '../models/base/responses';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export class BaseService {
  constructor(protected httpClient: HttpClient) {}

  protected list<R>(url: string, params?: object): Observable<R[]> {
    return this.httpClient.get<ApiSuccess<R[]>>(url, params).pipe(
      take(1),
      map(body => body.data)
    );
  }

  protected get<R>(url: string, params?: object): Observable<R> {
    return this.httpClient.get<ApiSuccess<R>>(url).pipe(
      take(1),
      map(body => body.data)
    );
  }

  protected update<R>(url: string, params: object): Observable<R> {
    return this.httpClient.put<ApiSuccess<R>>(url, params).pipe(
      take(1),
      map(body => body.data)
    );
  }

  protected create<R>(url: string, params: object): Observable<R> {
    return this.httpClient.post<ApiSuccess<R>>(url, params).pipe(
      take(1),
      map(body => body.data)
    );
  }

  protected delete(url: string): Observable<any> {
    return this.httpClient.delete(url).pipe(take(1));
  }
}
