import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, flatMap, map, shareReplay, startWith, take, tap } from 'rxjs/operators';
import { ConfirmationSuccess } from '../models/auth/account-confirmation';
import { PasswordReset } from '../models/auth/password-reset';
import { Session } from '../models/auth/session';
import { UserInfo, UserProfile } from '../models/auth/user-profile';
import { ApiResponse, ApiSuccess } from '../models/base/responses';
import { PhoneNumber } from '../models/phone-number';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static readonly UNAUTHED: UserInfo = { isAuthenticated: false, isLoading: false };
  public static readonly FETCHING: UserInfo = { isAuthenticated: false, isLoading: true };

  private userUpdated = new Subject<UserInfo>();
  public headers$: Observable<HttpHeaders> = of(new HttpHeaders());
  public user$: Observable<UserInfo> = merge(this.userUpdated, this.fetchUser()).pipe(shareReplay(1));
  public roles$ = this.user$.pipe(map((user) => (user.isAuthenticated && user.user.roles) || []));

  constructor(private httpClient: HttpClient, @Inject(DOCUMENT) private document: Document, private router: Router) {}

  public refetchUser() {
    this.fetchUser()
      .pipe(take(1))
      .toPromise()
      .then((user) => this.userUpdated.next(user));
  }

  public updateUser(user: UserProfile): Promise<UserProfile> {
    return this.headers$
      .pipe(
        flatMap((headers) => this.httpClient.put<ApiSuccess<UserProfile>>('/api/current-user', { user }, { headers })),
        map((body) => body.data),
        take(1),
        tap((result) => this.userUpdated.next({ user: result, isLoading: false, isAuthenticated: true }))
      )
      .toPromise();
  }

  public updateEmergencyContact(name: string, phone: PhoneNumber): Promise<UserProfile> {
    return this.headers$
      .pipe(
        flatMap((headers) =>
          this.httpClient.put<ApiSuccess<UserProfile>>(
            '/api/current-user/emergency-contact',
            {
              emergency_contact: {
                name,
                phone,
              },
            },
            { headers }
          )
        ),
        map((body) => body.data),
        take(1),
        tap((result) => {
          if (result) {
            this.userUpdated.next({ user: result, isLoading: false, isAuthenticated: true });
          }
        })
      )
      .toPromise();
  }

  public promptForLogin(mode: 'login' | 'register' = 'login'): Observable<any> {
    const currentHref = `${this.document.location.pathname}${this.document.location.search}`;

    if (mode === 'login') {
      this.document.location.href = '/sign-in?from=' + encodeURIComponent(currentHref);
    } else {
      this.document.location.href = '/register?from=' + encodeURIComponent(currentHref);
    }

    return of({});
  }

  private fetchUser(): Observable<UserInfo> {
    return this.httpClient
      .get<ApiResponse<UserProfile>>('/api/current-user', { observe: 'response' })
      .pipe(
        map((response) => {
          if (response.ok) {
            return (response.body as ApiSuccess<UserProfile>).data;
          } else {
            console.warn(response.body);
            return null;
          }
        }),
        map((user) => {
          if (user) {
            return { user, isAuthenticated: true, isLoading: false };
          } else {
            return AuthService.UNAUTHED;
          }
        }),
        catchError((err) => {
          console.error(err.message);

          return of(AuthService.UNAUTHED);
        }),
        startWith(AuthService.FETCHING)
      );
  }

  public confirmEmail(key: string): Promise<string> {
    const params = new HttpParams().append('key', key);
    return this.httpClient
      .get<ConfirmationSuccess>('/api/confirm', { params })
      .pipe(
        take(1),
        map((res) => res.info.detail)
      )
      .toPromise();
  }

  public signOut() {
    this.httpClient
      .post('/sign-out', {}, { observe: 'response', responseType: 'text' })
      .pipe(take(1))
      .toPromise()
      .then(() => {
        window.location.href = '/schedule';
      });
  }

  public requestNewConfirmation(email: string, password: string): Promise<any> {
    return this.httpClient.post('/api/confirm', { email, password }).pipe(take(1)).toPromise();
  }
  public logIn(email: string, password: string): Promise<Session> {
    return this.httpClient
      .post<Session>('/api/sessions', { session: { email, password } })
      .pipe(take(1))
      .toPromise();
  }

  public register(
    email: string,
    password: string,
    passwordConfirm: string,
    firstName: string,
    lastName: string
  ): Promise<UserProfile> {
    return this.httpClient
      .post<UserProfile>('/api/users', {
        user: {
          primary_email: { value: email },
          credentials: { password, password_confirmation: passwordConfirm },
          first_name: firstName,
          last_name: lastName,
        },
      })
      .pipe(take(1))
      .toPromise();
  }

  public resetPassword(email: string): Promise<PasswordReset> {
    return this.httpClient
      .post<PasswordReset>('/api/password_resets', {
        password_reset: { email },
      })
      .pipe(take(1))
      .toPromise();
  }

  public setNewPassword(key: string, password: string, passwordConfirmation: string): Promise<boolean> {
    return this.httpClient
      .put('/api/password_resets/update', {
        password_reset: { key, password, password_confirmation: passwordConfirmation },
      })
      .pipe(
        take(1),
        map(() => true)
      )
      .toPromise();
  }
}
