import { Inject, Injectable, OnDestroy } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Event, Severity } from '@sentry/types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserInfo, getPrimaryEmail, getPrimaryEmailValue } from '../models/auth/user-profile';
import { AuthService } from '../services/auth.service';
import { SentryConfig, SENTRY_CONFIG } from './sentry.config';
import { environment } from 'src/environments/environment';
import { versions } from 'src/environments/versions';

@Injectable({
  providedIn: 'root',
})
export class SentryService implements OnDestroy {
  private config = {
    enabled: environment.production,
    dsn: 'https://e0d10fe107ae41038a4fdcfcffa72187@sentry.io/1528839',
    environment: environment.production ? 'prod' : 'local',
    release: `schedule-guru-ui@${versions.revision}`,
  };
  private onDestroy = new Subject<void>();

  constructor(private authService: AuthService) {
    if (this.config && this.config.enabled) {
      Sentry.init({
        dsn: this.config.dsn,
        environment: this.config.environment,
        release: this.config.release,
      });

      this.authService.user$.pipe(takeUntil(this.onDestroy)).subscribe(this.handleUserUpdate);
    }
  }

  public captureException(error: any) {
    if (this.config && this.config.enabled) {
      Sentry.captureException(error);
    }
  }

  public captureEvent(event: Event) {
    if (this.config && this.config.enabled) {
      Sentry.captureEvent(event);
    }
  }

  public captureMessage(message: string, level?: Severity) {
    if (this.config && this.config.enabled) {
      Sentry.captureMessage(message, level);
    }
  }

  private handleUserUpdate(user: UserInfo): void {
    if (!this.config || !this.config.enabled) {
      return;
    }

    if (user.isAuthenticated) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: user.user.id,
          display_name: user.user.display_name,
          email: getPrimaryEmailValue(user.user),
        });
      });
    } else {
      Sentry.configureScope((scope) => {
        scope.clear();
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
