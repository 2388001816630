import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgModule } from '@angular/core';
import {
  ArrowLeftOutline,
  CalendarOutline,
  CaretLeftOutline,
  CaretRightOutline,
  ClockCircleOutline,
  CloseOutline,
  DeleteOutline,
  DownOutline,
  DownloadOutline,
  EditOutline,
  EyeOutline,
  EyeInvisibleOutline,
  FacebookOutline,
  FilterOutline,
  ForkOutline,
  GiftOutline,
  GlobalOutline,
  GoogleOutline,
  HomeOutline,
  InfoCircleOutline,
  LockOutline,
  LinkOutline,
  MailOutline,
  MenuOutline,
  MoreOutline,
  PhoneOutline,
  PlusCircleFill,
  PlusOutline,
  QuestionOutline,
  RollbackOutline,
  SearchOutline,
  ShoppingCartOutline,
  SlidersOutline,
  TwitterOutline,
  UpOutline,
  UserOutline,
  WarningOutline,
  WarningFill,
  LeftOutline,
  RightOutline,
} from '@ant-design/icons-angular/icons';

const icons: IconDefinition[] = [
  ArrowLeftOutline,
  CalendarOutline,
  CaretLeftOutline,
  CaretRightOutline,
  ClockCircleOutline,
  CloseOutline,
  DeleteOutline,
  DownOutline,
  DownloadOutline,
  EditOutline,
  EyeOutline,
  EyeInvisibleOutline,
  FacebookOutline,
  FilterOutline,
  ForkOutline,
  GiftOutline,
  GlobalOutline,
  GoogleOutline,
  HomeOutline,
  InfoCircleOutline,
  LeftOutline,
  LockOutline,
  LinkOutline,
  MailOutline,
  MenuOutline,
  MoreOutline,
  PhoneOutline,
  PlusCircleFill,
  PlusOutline,
  QuestionOutline,
  RightOutline,
  RollbackOutline,
  SearchOutline,
  ShoppingCartOutline,
  SlidersOutline,
  TwitterOutline,
  UpOutline,
  UserOutline,
  WarningOutline,
  WarningFill,
];

@NgModule({
  imports: [NzIconModule.forRoot(icons)],
})
export class CoreIconsProviderModule {}
