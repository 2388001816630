import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreakpointsService {
  // Indicates that the device is small or larger
  public isGtXs$ = this.breakpointObserver.observe('(min-width: 481px)').pipe(map(match => match.matches));
  // Indicates that the device is medium or larger
  public isGtSm$ = this.breakpointObserver.observe('(min-width: 576px)').pipe(map(match => match.matches));
  // Indicates that the device is small or smaller
  public isLtMd$ = this.isGtSm$.pipe(map(value => !value));
  // Indicates that the device is larger or larger
  public isGtMd$ = this.breakpointObserver.observe('(min-width: 768px)').pipe(map(match => match.matches));
  // Indicates that the device is medium or smaller
  public isLtLg$ = this.isGtMd$.pipe(map(value => !value));
  // Indicates that the device is extra large
  public isGtLg$ = this.breakpointObserver.observe('(min-width: 992px)').pipe(map(match => match.matches));

  constructor(private breakpointObserver: BreakpointObserver) {}
}
