<ng-container *ngIf="!isLoading; else loading">
  <div>
    <pre class="waiver-text">{{ waiver.waiver.contents }}</pre>
  </div>
  <form nz-form [formGroup]="formGroup" (ngSubmit)="submit()">
    <nz-form-item>
      <nz-form-label [nzSm]="12" [nzXs]="24" nzRequired for="acknowledge">
        I have read and agree to the liability waiver
      </nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="You must accept the liability waiver to continue">
        <label nz-checkbox formControlName="acknowledge" id="acknowledge"></label>
      </nz-form-control>
    </nz-form-item>

    <ng-container *ngIf="whoAmI.id !== user.id">
      <nz-form-item>
        <nz-form-label
          [nzSm]="12"
          [nzXs]="24"
          nzRequired
          for="canAcknowledgeForUser"
          nz-tooltip
          nzTooltipTitle="You either must be legally allowed to accept a waiver on behalf of this person, or have them present so they can acknowledge the waiver."
        >
          I am entitled to acknowledge this on behalf of {{ user.display_name }}
        </nz-form-label>
        <nz-form-control
          [nzSm]="12"
          [nzXs]="24"
          nzErrorTip="You must be able to accept the liability waiver to continue"
        >
          <label nz-checkbox formControlName="canAcknowledgeForUser" id="canAcknowledgeForUser"></label>
        </nz-form-control>
      </nz-form-item>
    </ng-container>
  </form>
</ng-container>

<ng-template #loading>
  <nz-spin></nz-spin>
</ng-template>

<div *nzModalFooter>
  <button type="button" nz-button nzType="primary" nzGhost (click)="cancel()">Cancel</button>
  <button nz-button nzType="primary" [nzLoading]="isSubmitting" [disabled]="isLoading" (click)="submit()">
    Acknowledge & Sign Waiver
  </button>
</div>
