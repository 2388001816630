import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { combineLatest, concat, interval, of } from 'rxjs';
import { delay, filter, first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UpdateWatcherService {
  constructor(appRef: ApplicationRef, updates: SwUpdate, notificationService: NzNotificationService) {
    if (updates.isEnabled) {
      updates.available.subscribe((event) => {
        console.log(`Found update: ${event.current.hash} -> ${event.available.hash}`);
        notificationService.info('Schedule Guru has been updated', 'The update will be applied on refresh.');
      });

      const initialDelay$ = of(1).pipe(delay(30 * 1000));
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnce$ = concat(initialDelay$, everySixHours$);

      everySixHoursOnce$.subscribe(() => {
        console.log('checking for updates');
        updates.checkForUpdate();
      });
    } else {
      console.log('Service worker not enabled :-(');
    }
  }
}
