import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { collapseMotion } from 'ng-zorro-antd/core/animation';
import { combineLatest, Observable, Subject } from 'rxjs';
import { delay, filter, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { versions } from 'src/environments/versions';
import { UserInfo, UserProfile } from './models/auth/user-profile';
import { BreakpointsService } from './responsive/breakpoints.service';
import { AppSettingsService } from './services/application-settings/app-settings.service';
import { AuthService } from './services/auth.service';
import { ViolatedRestriction } from './services/calendar/restrictions-violated.error';
import { UsersService } from './services/user/users.service';
import { ViolatedRestrictionResolverService } from './services/user/violated-restriction-resolver.service';
import { UpdateWatcherService } from './services/application-settings/update-watcher.service';
import { HttpXsrfTokenExtractor } from '@angular/common/http';
import { AppSettings } from './models/application-settings/app-settings';

type routeNameForUser = (user: UserInfo) => string;
type routeIsVisible = (user: UserInfo, settings: AppSettings) => boolean;

interface NavBarRoute {
  name: string | routeNameForUser;
  path?: string[];
  click?: () => void;
  sub_routes?: NavBarRoute[];
  exact?: boolean;
  show?: routeIsVisible;
}

export interface ResolvedNavBarRoute {
  name: string;
  path?: string[];
  click?: () => void;
  sub_routes: ResolvedNavBarRoute[];
  exact: boolean;
  show?: routeIsVisible;
}

export interface SidebarMenuItem {
  name: string;
  path: string[];
  exact: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [collapseMotion],
})
export class AppComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();
  private routesDef: NavBarRoute[] = [
    {
      name: 'Schedule',
      path: ['schedule'],
      sub_routes: [
        {
          name: 'Calendar',
          path: ['schedule'],
          exact: true,
          show: this.hasAnyActionGroup('attendance_record', 'course_sessions', 'session_classes', 'courses'),
        },
        {
          name: 'Attendance',
          path: ['schedule', 'attendance'],
          exact: true,
          show: this.hasAnyActionGroup('attendance_record'),
        },
        {
          name: 'Session',
          path: ['schedule', 'sessions'],
          show: this.hasAnyActionGroup('course_sessions', 'session_classes'),
        },
        {
          name: 'Courses',
          path: ['schedule', 'courses'],
          show: this.hasAnyActionGroup('courses'),
        },
      ],
    },
    {
      name: 'Locations',
      path: ['locations'],
    },
    {
      name: 'Store',
      path: ['store'],
      show: (user, settings) => settings.show_store_tab,
      sub_routes: [
        {
          name: 'Store Front',
          path: ['store'],
          exact: true,
          show: this.hasAnyActionGroup('products', 'payment_tags', 'billing_categories'),
        },
        {
          name: 'Products',
          path: ['store', 'products'],
          show: this.hasAnyActionGroup('products'),
        },
        {
          name: 'Tags Management',
          path: ['store', 'tags'],
          show: this.hasAnyActionGroup('payment_tags'),
        },
        {
          name: 'Billing Categories',
          path: ['store', 'categories'],
          show: this.hasAnyActionGroup('billing_categories'),
        },
      ],
    },
    {
      name: 'Admin',
      path: ['admin'],
      show: this.hasAnyActionGroup(
        'app_settings',
        'admin_settings',
        'stripe_settings',
        'statistics',
        'users',
        'event_credits',
        'invoices',
        'linked_accounts'
      ),
      sub_routes: [
        {
          name: 'Application Settings',
          path: ['admin', 'settings'],
          exact: true,
          show: this.hasAnyActionGroup('app_settings', 'admin_settings', 'stripe_settings'),
        },
        {
          name: 'Reports',
          path: ['admin', 'reports'],
          exact: true,
          show: this.hasAnyActionGroup('statistics'),
        },
        {
          name: 'User Management',
          path: ['admin', 'users'],
          exact: false,
          show: this.hasAnyActionGroup('users', 'event_credits', 'invoices', 'linked_accounts'),
        },
        {
          name: 'Role Management',
          path: ['admin', 'user-roles'],
          exact: false,
          show: this.hasAnyActionGroup('roles'),
        },
      ],
    },
    {
      name: 'Try the Beta UI',
      click: () => (window.location.href = '/beta'),
    },
    {
      name: (user) => user.user.display_name,
      show: (user) => user.isAuthenticated,
      path: ['profile'],
      sub_routes: [
        {
          name: 'Profile',
          path: ['profile'],
          exact: true,
          show: (user) => user.isAuthenticated,
        },
        {
          name: 'Payment   History',
          path: ['profile', 'payment-history'],
          show: (user) => user.isAuthenticated,
        },
        {
          name: 'Linked Accounts',
          path: ['profile', 'linked-accounts'],
          show: (user) => user.isAuthenticated,
        },
        {
          name: 'Sign Out',
          show: (user) => user.isAuthenticated,
          click: () => this.signOut(),
        },
      ],
    },
    {
      name: 'Sign In',
      show: (user) => !user.isAuthenticated,
      click: () => this.signIn(),
    },
    {
      name: 'Register',
      show: (user, settings) => !user.isAuthenticated && settings.registration_enabled,
      click: () => this.register(),
    },
  ];

  private user$ = this.authService.user$.pipe(filter((user) => !user.isLoading));

  public navBarEntries$ = combineLatest([this.user$, this.appSettingsService.settings$]).pipe(
    map((info) => this.visibleRoutesFor(this.routesDef, info[0], info[1])),
    shareReplay(1)
  );

  public currentUser$: Observable<UserInfo> = this.user$.pipe(startWith(AuthService.UNAUTHED));
  public isAuthenticated$ = this.currentUser$.pipe(
    map((user) => {
      return user && user.isAuthenticated;
    })
  );

  public versions = versions;

  public date = new Date();
  public currentEntryName = '';
  public mobileMenuVisible = false;

  public brandName$ = this.appSettingsService.applicationName$;
  public brandUrl$ = this.appSettingsService.applicationBrandUrl$;
  public loading$ = this.router.events.pipe(
    filter(
      (event) =>
        event instanceof NavigationStart ||
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
    ),
    map((event) => {
      switch (true) {
        case event instanceof NavigationStart:
          return true;
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
          return false;
        default:
          return true;
      }
    }),
    delay(0),
    startWith(true),
    shareReplay(1)
  );
  private urlParts$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event) => (event as NavigationEnd).urlAfterRedirects),
    map((url) => url.replace(/\?.*$/, '')),
    map((url) => url.split('/').filter((str) => !!str)),
    shareReplay(1)
  );
  public currentEntry$ = combineLatest([this.navBarEntries$, this.urlParts$]).pipe(
    map(([entries, section]) => entries.find((entry) => entry.path && entry.path[0] === section[0])),
    tap((entry) => (this.currentEntryName = (entry && entry.name) || '')),
    shareReplay(1)
  );
  public sideBarMenus$ = this.currentEntry$.pipe(
    map((entry) => (entry && entry.sub_routes) || []),
    shareReplay(1)
  );

  public showMobileMenu$ = this.breakpoints.isGtMd$.pipe(
    map((greaterThanMd) => !greaterThanMd),
    tap(() => (this.mobileMenuVisible = false)),
    shareReplay(1)
  );

  public breadcrumbs$ = combineLatest([this.currentEntry$, this.urlParts$]).pipe(
    map(([entry, url]) => this.determineCurrentBreadcrumbs(entry, url)),
    shareReplay(1)
  );

  constructor(
    public router: Router,
    private authService: AuthService,
    private modalSerivce: NzModalService,
    private appSettingsService: AppSettingsService,
    private userService: UsersService,
    private title: Title,
    public breakpoints: BreakpointsService,
    private notificationService: NzNotificationService,
    private restrictionResolver: ViolatedRestrictionResolverService,
    public tokenHolder: HttpXsrfTokenExtractor,
    // @ts-ignore: required to wire up update checker
    private updateWatcher: UpdateWatcherService
  ) {}

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  hasAnyActionGroup(...groups: string[]): routeIsVisible {
    return (user) =>
      user.isAuthenticated &&
      user.user.actions.some((userAction) =>
        groups.some((group) => userAction.group === '*' || userAction.group === group)
      );
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => {
        this.mobileMenuVisible = false;
        this.modalSerivce.closeAll();
      });

    combineLatest([this.currentEntry$, this.appSettingsService.applicationName$])
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([entry, name]) => {
        if (entry) {
          this.title.setTitle(`${entry.name} - ${name}`);
        } else {
          this.title.setTitle(name);
        }
      });

    this.user$
      .pipe(
        filter((user) => user.isAuthenticated),
        map((user) => user.user),
        switchMap((user) =>
          this.userService
            .getAccountRestrictions(user)
            .then((restrictions) => [user, restrictions] as [UserProfile, ViolatedRestriction[]])
        ),
        filter(([user, restrictions]) => restrictions.length > 0),
        takeUntil(this.onDestroy)
      )
      .subscribe(async ([user, restrictions]) => {
        for (const restriction of restrictions) {
          const handled = await this.restrictionResolver.resolveRetriction(user, restriction);

          if (!handled) {
            this.notificationService.warning('Account restricted', restriction.message);
          }
        }
      });
  }

  isCurrentRoute(route: ResolvedNavBarRoute) {
    return this.urlParts$.pipe(
      map((url) => {
        if (route.exact) {
          return this.arrayEquals(route.path, url);
        } else {
          return this.arrayIsSubset(route.path, url);
        }
      })
    );
  }

  navBarRouteTrackFn(entry: ResolvedNavBarRoute) {
    return entry.name;
  }

  signOut() {
    this.authService.signOut();
  }

  signIn() {
    this.authService.promptForLogin('login');
  }

  register() {
    this.authService.promptForLogin('register');
  }

  private visibleRoutesFor(defs: NavBarRoute[], user: UserInfo, settings: AppSettings): ResolvedNavBarRoute[] {
    return defs.filter((route) => this.isVisible(route, user, settings)).map((route) => this.routeForUser(route, user, settings));
  }

  private isVisible(route: NavBarRoute, user: UserInfo, settings: AppSettings): boolean {
    return route.show === undefined || route.show(user, settings);
  }

  private routeForUser(route: NavBarRoute, user: UserInfo, settings: AppSettings): ResolvedNavBarRoute {
    return {
      ...route,
      exact: !!route.exact,
      name: this.isString(route.name) ? route.name : route.name(user),
      sub_routes: route.sub_routes === undefined ? [] : this.visibleRoutesFor(route.sub_routes, user, settings),
    };
  }

  private isString(name: string | routeNameForUser): name is string {
    return typeof name === 'string';
  }

  private determineCurrentBreadcrumbs(entry: ResolvedNavBarRoute, url: string[]) {
    if (!entry) {
      return [];
    }

    if (!entry.sub_routes || !entry.path) {
      return [entry];
    }

    const subRoute = entry.sub_routes.find((route) => {
      if (route.exact) {
        return this.arrayEquals(route.path, url);
      } else {
        return this.arrayIsSubset(route.path, url);
      }
    });

    if (subRoute) {
      return [entry, subRoute];
    } else {
      return [entry];
    }
  }

  private arrayEquals(a: string[], b: string[]) {
    if (a === b) {
      return true;
    }
    if (a == null || b == null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }

  private arrayIsSubset(a: string[], b: string[]) {
    if (a === b) {
      return true;
    }
    if (a == null || b == null) {
      return false;
    }
    if (a.length > b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
}
